import { AxiosPromise } from 'axios'
import httpRequest from './httpRequest'


export const prefixRouter  = '#';
interface _GetPhoneCodeData {
  vcode: string
}
/**
 * 获取手机验证码
 * @param data 
 * @returns 
 */
export function getPhoneCode(data :string): AxiosPromise {
  return httpRequest({
    url: '/auth/getSmsCode?phoneNumber='+data,
    method: 'get',
    
  })
}
/**
 * 获取国际手机验证码
 * @param data 
 * @returns 
 */
 export function getPhongetInternationalSmsCodeeCode(areaCode :string,phoneNumber :string): AxiosPromise {
  return httpRequest({
    url: '/auth/getInternationalSmsCode?areaCode='+areaCode+'&phoneNumber='+phoneNumber,
    method: 'get',
    
  })
}
/**
 * 获取邮箱验证码
 * @param data 
 * @returns 
 */
 export function getEmailCode(data :string): AxiosPromise {
  return httpRequest({
    url: '/auth/getEmailCode?email='+data,
    method: 'get',
    
  })
}


// interface _LoginData {
//   username: string
//   password: string
//   rememberMe: boolean
// }
/**
 * 嗨芯网登录
 * @param data 
 * @returns 
 */
export function login(data = {}): AxiosPromise {
  return httpRequest({
    url: `/auth/hxwlogin`,
    method: 'post',
    data
  })
}
/**
 * 嗨芯网国际登录
 * @param data 
 * @returns 
 */
 export function hxwInternationalLogin(data = {}): AxiosPromise {
  return httpRequest({
    url: `/auth/hxwInternationalLogin`,
    method: 'post',
    data
  })
}
interface _NewsData {
  pageSize: number
}
export function getNews(data: _NewsData): AxiosPromise {
  return httpRequest({
    url: `/api/news`,
    method: 'post',
    data
  })
}
/**
 * 获取用户信息
 * @param params 
 * @returns 
 */
export function getUserInfo(params = {}):AxiosPromise {
  return httpRequest({
    url: `/system/user/getInfo`,
    method: 'get',
    params:params
  })
}
/**
 * 嗨芯网注册
 * @param data 
 * @returns 
 */
export function hxwReg(data = {}): AxiosPromise {
  return httpRequest({
    url: `/auth/hxwReg`,
    method: 'post',
    data:data
  })
}
/**
 * 嗨芯网国际注册
 * @param data 
 * @returns 
 */
 export function hxwInternationalReg(data = {}): AxiosPromise {
  return httpRequest({
    url: `/auth/hxwInternationalReg`,
    method: 'post',
    data:data
  })
}
/**
 * 嗨芯网退出登录
 * @returns 
 */
export function hxwLogout(): AxiosPromise {
  return httpRequest({
    url: `/auth/hxwLogout`,
    method: 'delete',
    data:null
  })
}
/**
 * 修改用户信息
 * @param params 
 * @returns 
 */
 export function hxwUpdateProfile(data = {}):AxiosPromise {
  return httpRequest({
    url: `/system/user/profile/hxwUpdateProfile`,
    method: 'put',
    data:data
  })
}
/**
 * 获取用户地址列表
 * @param params 
 * @returns 
 */
export function listUserAddress(params = {}):AxiosPromise {
  return httpRequest({
    url: `/offer/goods/listByCurrentCustomer`,
    method: 'get',
    params:params
  })
}
/**
 * 新增-用户地址
 * @param data 
 * @returns 
 */
 export function insertUserAddress(data = {}): AxiosPromise {
  return httpRequest({
    url: `/offer/goods`,
    method: 'post',
    data:data
  })
}
/**
 * 修改-用户地址
 * @param data 
 * @returns 
 */
 export function updateUserAddress(data = {}): AxiosPromise {
  return httpRequest({
    url: `/offer/goods`,
    method: 'put',
    data:data
  })
}
/**
 * 删除-用户地址
 * @param params 
 * @returns 
 */
 export function delUserAddress(params :any ):AxiosPromise {
  return httpRequest({
    url: `/offer/goods/del/`+params,
    method: 'delete',
    params
  })
}
/**
 * 获取认证客户详细信息
 * @param params 
 * @returns 
 */
 export function getCustomerInfo(params :any):AxiosPromise {
  return httpRequest({
    url: `/offer/customer/hxw/`+params,
    method: 'get',
    params:params
  })
}
/**
 * 认证公司
 * @param data 
 * @returns 
 */
 export function identifyCustomer(data = {}): AxiosPromise {
  return httpRequest({
    url: `/offer/customer/identifyCustomer`,
    method: 'post',
    data:data
  })
}
/**
 * 感兴趣
 * @param num 
 * @returns 
 */
export function getRecoProducts(num: number): AxiosPromise {
  return httpRequest({
    url: `/api/recoproduct`,
    method: 'post',
    data: { num },
  })
}

export function getProductById(id: number): AxiosPromise {
  return httpRequest({
    url: `/api/product`,
    method: 'post',
    data: { id },
  })
}
/**
 * OA-根据型号进行搜索物料
 * @param params 
 * @returns 
 */
 export function listMateriaByPartnumber(params :any):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwSearch/listMateriaByPartnumber?partnumber=`+params,
    method: 'get',
    params:params
  })
}
/**
 * 爬虫-根据型号进行搜索物料
 * @param params 
 * @returns 
 */
 export function callReptileGetMateriaList(params :any):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwSearch/callReptileGetMateriaList?partnumber=`+params,
    method: 'get',
    params:params
  })
}
/**
 * OA-获取物料详情
 * @param params 
 * @returns 
 */
 export function callOaGetMateriaDetail(params :any):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwSearch/callOaGetMateriaDetail?id=`+encodeURIComponent(params),
    method: 'get',
    params:params
  })
}

/**
 * OA-获取分类列表
 * @param params 
 * @returns 
 */
 export function listCallOaCategory(params :any):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwSearch/listCallOaCategory`,
    method: 'get',
    params:params
  })
}

/**
 * OA-根据分类进行搜索物料
 * @param params 
 * @returns 
 */
 export function listCallOaResultVoByCategoryId(params :any):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwSearch/listCallOaResultVoByCategoryId?categoryId=`+params,
    method: 'get',
    params:params
  })
}
/**
 * 获取购物车列表
 * @param params 
 * @returns 
 */
 export function selectByUserId(params = {}):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwShoppingCar/selectByUserId`,
    method: 'get',
    params:params
  })
}
/**
 * 新增-购物车
 * @param data 
 * @returns 
 */
 export function addShoppingCart(data = {}): AxiosPromise {
  return httpRequest({
    url: `/offer/hxwShoppingCar/addShoppingCart`,
    method: 'post',
    data:data
  })
}
/**
 * 修改-购物车
 * @param data 
 * @returns 
 */
 export function updateShoppingCart(data = {}): AxiosPromise {
  return httpRequest({
    url: `/offer/hxwShoppingCar/updateShoppingCart`,
    method: 'put',
    data:data
  })
}
/**
 * 删除-购物车
 * @param params 
 * @returns 
 */
 export function delShoppingCart(params :any ):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwShoppingCar/delShoppingCart/`+params,
    method: 'delete',
    params
  })
}
/**
 * 批量删除-购物车
 * @param params 
 * @returns 
 */
 export function batchDelShoppingCart(data = [] ):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwShoppingCar/batchDelShoppingCart`,
    method: 'delete',
    data:data
  })
}
/**
 * 重置密码
 * @param data 
 * @returns 
 */
 export function updateUserPwd(data = {'newPassword':'','rePassword':''}): AxiosPromise {
  return httpRequest({
    url: `/system/user/updateUserPwd?newPassword=`+data.newPassword+`&rePassword=`+data.rePassword,
    method: 'post',
    data:data
  })
}
/**
 * 获取用户发票列表
 * @param params 
 * @returns 
 */
export function listUserInvoice(params : string):AxiosPromise {
  return httpRequest({
    url: `/offer/invoice/listUserInvoice?type=`+params,
    method: 'get',
  })
}
/**
 * 新增-用户发票
 * @param data 
 * @returns 
 */
 export function insertUserInvoice(data = {}): AxiosPromise {
  return httpRequest({
    url: `/offer/invoice/insertUserInvoice`,
    method: 'post',
    data:data
  })
}
/**
 * 修改-用户发票
 * @param data 
 * @returns 
 */
 export function updateUserInvoice(data = {}): AxiosPromise {
  return httpRequest({
    url: `/offer/invoice/updateUserInvoice`,
    method: 'put',
    data:data
  })
}
/**
 * 删除-用户发票
 * @param params 
 * @returns 
 */
 export function delUserInvoice(params :any ):AxiosPromise {
  return httpRequest({
    url: `/offer/invoice/delUserInvoice?id=`+params,
    method: 'delete',
    params
  })
}
/**
 * OA-检查是否有失效物料--购物车入口
 * @param params 
 * @returns 
 */
 export function checkInvalidMaterialList(data = [] ):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwOrder/checkInvalidMaterialList`,
    method: 'post',
    data:data,
    
  })
}
/**
 * OA-检查是否有失效物料--产品详情入口
 * @param params 
 * @returns 
 */
 export function checkInvalidAndOrder(data = [],param:string ):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwOrder/checkInvalidAndOrder/`+param,
    method: 'post',
    data:data,
    
  })
}
/**
 * 订单-订单提交
 * @param data 
 * @returns 
 */
 export function commitOrder(data = {}): AxiosPromise {
  return httpRequest({
    url: `/offer/hxwOrder/commitOrder`,
    method: 'post',
    data:data
  })
}
/**
 * 订单-分页
 * @param params 
 * @returns 
 */
 export function pageOrder(params = {}):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwOrder/pageOrder`,
    method: 'get',
    params:params
  })
}
/**
 * 订单-查询订单支付状态
 * @param params 
 * @returns 
 */
 export function queryPayStatus(params  : string):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwOrder/queryPayStatus?orderMainId=`+params,
    method: 'get',
    params
  })
}
/**
 * 订单-明细分页
 * @param params 
 * @returns 
 */
 export function pageOrderDetail(params = {}):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwOrder/pageOrderDetail`,
    method: 'get',
    params:params
  })
}
/**
 * 订单-凭证列表
 * @param params 
 * @returns 
 */
 export function attachmentsList(params = {},id:string,moduleId :string):AxiosPromise {
  return httpRequest({
    url: `/offer/attachments/pageAttachments/`+id+`/`+moduleId,
    method: 'get',
    params:params
  })
}
/**
 * 订单-凭证删除
 * @param params 
 * @returns 
 */
 export function deleteAttachments(id : number):AxiosPromise {
  return httpRequest({
    url: `/offer/attachments/remove?id=`+id,
    method: 'get',
  })
}
/**
 * 订单-开票分页
 * @param params 
 * @returns 
 */
 export function pageOrderInvoice(params = {}):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwOrder/pageOrderInvoice`,
    method: 'get',
    params:params
  })
}

/**
 * 邮箱验证
 * @param data 
 * @returns 
 */
 export function verifyEmail(data = {}): AxiosPromise {
  return httpRequest({
    url: `/system/user/verifyEmail`,
    method: 'post',
    data:data
  })
}

/**
 * 验证旧手机
 * @param data 
 * @returns 
 */
 export function validateOldMobile(data = {verifyCode:""}): AxiosPromise {
  return httpRequest({
    url: `/system/user/validateOldMobile?verifyCode=`+data.verifyCode,
    method: 'post',
    data:data
  })
}

/**
 * 验证新手机
 * @param data 
 * @returns 
 */
 export function validateNewMobile(data = {verifyCode:"",newMobile:""}): AxiosPromise {
  return httpRequest({
    url: `/system/user/validateNewMobile?newMobile=`+data.newMobile+"&verifyCode="+data.verifyCode,
    method: 'post',
    data:data
  })
}
/**
 * 忘记密码
 * @param data 
 * @returns 
 */
 export function forgetPassReset(data = {}): AxiosPromise {
  return httpRequest({
    url: `/auth/forgetPassReset`,
    method: 'post',
    data:data
  })
}
/**
 * OA-获取相关物料列表
 * @param params 
 * @returns 
 */
 export function listCallOaGetRela(params :string):AxiosPromise {
  return httpRequest({
    url: `/offer/hxwSearch/listCallOaGetRela?materialId=`+params,
    method: 'get',
  })
}
  /**
 * OA-根据用户输入提供物料型号列表提示
 * @param params 
 * @returns 
 */
export function  listPartNumberTips(params :string):AxiosPromise {
  return   httpRequest({
    url: `/offer/hxwSearch/listPartNumberTips?partNumber=`+params,
    method: 'get',
    
  })
}
/**
 * 获取用户地址列表
 * @param moduleId 
 * @returns 
 */
 export function listCustomerFile(moduleId :number):AxiosPromise {
  return httpRequest({
    url: `/offer/customer/listCustomerFile/`+moduleId,
    method: 'get',
  })
}
/**
 * 下载
 * @param id 
 * @returns 
 */
 export function downloadCustomerFlie(id :number):AxiosPromise {
  return httpRequest({
    url: `/offer/customer/downloadfile?id=`+id,
    method: 'get',
    responseType:'blob'
  })
}
/**
 * 删除附件记录
 * @param id 
 * @returns 
 */
 export function removeAttachments(id :number): AxiosPromise {
  return httpRequest({
    url: `/offer/customer/removeAttachments?id=`+id,
    method: 'post'
  })
}
  /**
 * 校验当前登录用户是否进行了bms认证或者嗨芯网认证
 * @returns 
 */
   export function  checkCustomerStatus( ):AxiosPromise {
    return   httpRequest({
      url: `/offer/hxwOrder/checkCustomerStatus`,
      method: 'get',
      
    })
  }