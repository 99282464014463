import axios from "axios";
import Vue from "vue";
import router from "@/router";
const AUTH_TOKEN = '***';

//本地环境
//export const baseURL  = 'http://10.0.90.110:8080/';
 
//测试环境
//export const baseURL  = 'http://10.0.91.80:8080/';
//生产环境
export const baseURL  = 'https://bms.heyelec.com:18443/';
//export const baseURL  = 'http://94.74.123.17:8080/';


// Set config defaults when creating the instance
export const instance = axios.create({
  baseURL: baseURL
});

// Alter defaults after instance has been created
instance.defaults.headers.common['Authorization'] = window.localStorage.getItem("Authorization");

// http request 请求拦截器，有token值则配置上token值
instance.interceptors.request.use(
  config => {
    // let token = window.localStorage.getItem("Authorization")
    if (window.localStorage.getItem("Authorization")) {  // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
      config.headers.Authorization = window.localStorage.getItem("Authorization");
    }
    // sratload();
    return config;
  },
  err => {
    return Promise.reject(err);
  });
// http response 服务器响应拦截器，这里拦截401错误，并重新跳入登页重新获取token
instance.interceptors.response.use(
  response => {
    // if (response.status == 200 || response.status == 201){  
    //   endload();
    // }
    
    // console.log(response.status)
    if(response.status == 200){
      if(response.data && response.data.code == 401 ){
         // console.log(error.response.status,"录已失效，请重新登录")
      // Vue.prototype.$message('登录已失效，请重新登录！！')
      localStorage.removeItem('Authorization');
      router.push('/login')
      
      }
    }  
      
    return response;
  },
  error => {
    // console.log("222222",error.response)
    if (error.response) {
      // console.log(error.response.status)
      if(error.response.status == 200){
        if(error.response.data && error.response.data.code == 401 )
        // console.log(error.response.status,"录已失效，请重新登录")
        Vue.prototype.$message('登录已失效，请重新登录！！')
        localStorage.removeItem('Authorization');
        router.push('/login')
        
      }
    }
    // return Promise.reject(error.response.data)
  });

export default instance